import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const SentMessage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/?fade-in');
    }, 3500); 
    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <div className="sent-screen">
      <span className="pulse-fade-out">sent.</span>
    </div>
  );
};

export default SentMessage;
