import React, { useState, useEffect } from 'react';
import { fetchWeatherData, fetchTimeData } from './dataService';
import { translate, translateWeatherCondition } from './translationService';
import { Link } from 'react-router-dom';
import { generateBannerContent } from './utils';

interface AboutProps {
  language: 'en' | 'es';
}

const About: React.FC<AboutProps> = ({ language }) => {
  const [weather, setWeather] = useState<any>(null);
  const [time, setTime] = useState<string>(fetchTimeData());
  const [date, setDate] = useState<string>('');

  useEffect(() => {
    const getWeatherData = async () => {
      const weatherData = await fetchWeatherData();
      setWeather(weatherData);
    };

    getWeatherData();

    const timeInterval = setInterval(() => {
      setTime(fetchTimeData());
    }, 1000);

    return () => clearInterval(timeInterval);
  }, []);

  useEffect(() => {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    setDate(now.toLocaleDateString(language === 'es' ? 'es-ES' : 'en-US', options));
  }, [language]);

  return (
    <main>
      <section className="about">
        <h1>{translate('infoTitle', language)}</h1>
        <div className="content">
          <img src="/unique-about-page.jpg" alt="Unique Sada" className="about-image" />
          <div className="description">
            <div className="description-top">
              <p className="name"><b>{translate('name', language)}</b></p>
              <p className="jobTitle"><b>{translate('jobTitle', language)}</b></p>
              <p>{translate('aboutText', language)}</p>
              <p>{translate('aboutTextContinued', language)}</p>
            </div>
            <div className="description-bottom">
              <p className="weather-city">
                <em>{`Los Angeles, California - ${time} PT - ${weather ? `${weather.current.temp_f}${translate('tempUnit', language)}, ${translateWeatherCondition(weather.current.condition.text, language)}, ${date}` : ''}`}</em>
              </p>
              <p>
              <strong>{translate('emailPress', language)}</strong>
              <br />
              <Link to="/contact">{translate('email', language)}</Link>
            </p>
          </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default About;
