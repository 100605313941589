import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import "./App.css";
import Captcha from "./Captcha";
import About from "./About";
import Contact from "./Contact";
import { generateBannerContent } from "./utils";
import { translate } from './translationService';

const App: React.FC = () => {
  const [verified, setVerified] = useState(false);
  const [language, setLanguage] = useState<'en' | 'es'>('en');
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const hasVisited = sessionStorage.getItem("visited");
    if (hasVisited) {
      setVerified(true);
    }
  }, []);

  const handleSuccess = () => {
    setFadeOut(true);
    setTimeout(() => {
      sessionStorage.setItem("visited", "true");
      setVerified(true);
    }, 300);
  };

  const handleLanguageChange = (newLanguage: 'en' | 'es') => {
    setLanguage(newLanguage);
  };
  
  const FooterWithBanner: React.FC<{ language: 'en' | 'es' }> = ({ language }) => {
    const location = useLocation();
  
    if (location.pathname === '/contact') {
      return null;
    }
  
    return (
      <footer>
        <div className="banner" key={language}>
          <div className="banner-content">{generateBannerContent(language)}</div>
        </div>
      </footer>
    );
  };
  
  return (
    <Router>
      <div className="App">
        {!verified && (
          <div className={`captcha-container ${fadeOut ? 'fade-out' : ''}`}>
            <Captcha onSolved={handleSuccess} />
          </div>
        )}
        <div className={`about-container ${verified ? 'fade-in' : 'hidden'}`}>
          <header>
            <div className="language-switch">
              <button 
                className={language === 'en' ? 'selected' : ''} 
                onClick={() => handleLanguageChange('en')}
              >
                {translate('languageSwitch', 'en').split(' | ')[0]}
              </button> 
              | 
              <button 
                className={language === 'es' ? 'selected' : ''} 
                onClick={() => handleLanguageChange('es')}
              >
                {translate('languageSwitch', 'en').split(' | ')[1]}
              </button>
            </div>
          </header>
          <Routes>
            <Route path="/" element={<About language={language} />} />
            <Route path="/contact" element={<Contact language={language} />} />
          </Routes>
          <FooterWithBanner language={language} />
        </div>
      </div>
    </Router>
  );
};

export default App;