import { translate } from './translationService';

export const generateBannerContent = (language: 'en' | 'es') => {
  const content = [];
  const appleMusicLink = "https://music.apple.com/profile/unisada";
  const arenaReadingLink = "https://www.are.na/unique-sada/channels";
  
  for (let i = 0; i < 1000; i++) {
    content.push(
      <a key={i * 2} href={arenaReadingLink}>
        {translate('read', language)}
      </a>
    );
    content.push(
      <a key={i * 2 + 1} href={appleMusicLink}>
        {translate('listen', language)}
      </a>
    );
  }
  
  return content;
};
