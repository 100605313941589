import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { translate } from './translationService';
import SentMessage from './SentMessage';
import './Contact.css'; 

interface ContactProps {
  language: 'en' | 'es';
}

const Contact: React.FC<ContactProps> = ({ language }) => {
  const [formData, setFormData] = useState({ name: '', pronouns: '', email: '', description: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmitted(true);

    emailjs.send('service_xn7fh0e', 'template_7x2uyvq', formData, 'JMMlG4_HqVgXd5N2L')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  const handleCancel = () => {
    setFadeOut(true);
    navigate('/');
  };

  return (
    <main className={`contact-page fade-in`}>
      <section className="contact">
        {formSubmitted ? (
          <SentMessage />
        ) : (
          <form className="contact-form" onSubmit={handleSubmit}>
            <h2>{translate('contactFormTitle', language)}</h2>
            <label>
              {translate('nameLabel', language)}
              <input type="text" name="name" value={formData.name} onChange={handleInputChange} required />
            </label>
            <label>
              {translate('pronounsLabel', language)}
              <input type="text" name="pronouns" value={formData.pronouns} onChange={handleInputChange} required />
            </label>
            <label>
              {translate('emailLabel', language)}
              <input type="email" name="email" value={formData.email} onChange={handleInputChange} required />
            </label>
            <label>
              {translate('descriptionLabel', language)}
              <textarea name="description" value={formData.description} onChange={handleInputChange} required />
            </label>
            <div className="button-group">
              <button type="button" onClick={handleCancel} className={`cancel-button`}>{translate('cancelButton', language)}</button>
              <button type="submit" className="submit-button">{translate('submitButton', language)}</button>
            </div>
          </form>
        )}
      </section>
    </main>
  );
};

export default Contact;
