import React, { FC } from 'react';
import JigsawPuzzle from './JigsawPuzzle';

const Captcha: FC<{ onSolved: () => void }> = ({ onSolved }) => {
  return (
    <div className="captcha-container">
      <JigsawPuzzle
        imageSrc="/images/blended_corners.jpeg"
        rows={3}
        columns={3}
        onSolved={onSolved}
      />
    </div>
  );
};

export default Captcha;
