interface Translation {
  name: string;
  infoTitle: string;
  jobTitle: string;
  aboutText: string;
  aboutTextContinued: string;
  emailPress: string;
  email: string;
  languageSwitch: string;
  menuIcon: string;
  tempUnit: string;
  contactFormTitle: string;
  nameLabel: string;
  pronounsLabel: string;
  emailLabel: string;
  descriptionLabel: string;
  submitButton: string;
  cancelButton: string;
  formSubmittedMessage: string;
  read: string;
  listen: string;
}

interface Translations {
  en: Translation & { weatherConditions: { [key: string]: string } };
  es: Translation & { weatherConditions: { [key: string]: string } };
}

const translations: Translations = {
  en: {
    name:"Unique Sada",
    infoTitle: "INFO",
    jobTitle: "Designer, Creative Consultant, Urban Planner + Analyst",
    aboutText:
    "Unique Sada redefines collective spaces as we know them. Her focuses include spatial design, cultural preservation, and social connectivity. Through her work, Unique pushes the boundaries of conventional planning and highlights the various social inequalities present in marginalized communities.",
    aboutTextContinued: "Unique is currently pursuing a Masters in Urban Planning from the University of Southern California. She has extensive experience both in the public and private sectors --- from large-scale projects to local community-focused site designing. Full CV available upon request.",
    emailPress: "CONTACT / PRESS",
    email: "contact@unique-sada.works",
    languageSwitch: "EN | ESP",
    menuIcon: "☰",
    tempUnit: "°F",
    contactFormTitle: "Contact Unique.",
    nameLabel: "Name",
    pronounsLabel: "Pronouns",
    emailLabel: "Email",
    descriptionLabel: "Description",
    submitButton: "Submit",
    cancelButton: "Cancel",
    formSubmittedMessage: "Thank you for your message! We will get back to you soon.",
    weatherConditions: {
      Clear: "Clear",
      "Partly cloudy": "Partly cloudy",
      Cloudy: "Cloudy",
      Overcast: "Overcast",
      Mist: "Mist",
      "Patchy rain possible": "Patchy rain possible",
      "Light rain": "Light rain",
      Sunny: "Sunny",
    },
    read: "READ",
    listen: "LISTEN",
  },
  es: {
    name: "Unique Sada",
    infoTitle: "INFORMACIÓN",
    jobTitle:"Diseñadora, Consultora Creativa, Urbanista + Analista",
    aboutText:
      "Unique Sada redefine los espacios colectivos tal y como los conocemos. Sus enfoques incluyen el diseño espacial, la preservación cultural y la conectividad social. A través de su trabajo, Unique supera los límites de la planificación convencional y enfatiza las diversas desigualdades sociales presentes en las comunidades marginadas.",
    aboutTextContinued: "Unique actualmente está cursando una Maestría en Planificación Urbana de la Universidad del Sur de California. Tiene una amplia experiencia tanto en el sector público como en el privado--- desde proyectos a gran escala hasta el diseño de sitios centrados en la comunidad local. CV completo disponible bajo petición.",
    emailPress: "CONTACTO / PRENSA",
    email: "contact@unique-sada.works",
    languageSwitch: "EN | ESP",
    menuIcon: "☰",
    tempUnit: "°F",
    contactFormTitle: "Contácto Unique.",
    nameLabel: "Nombre",
    pronounsLabel: "Pronombres",
    emailLabel: "Correo Electrónico",
    descriptionLabel: "Descripción",
    submitButton: "Enviar",
    cancelButton: "Cancelar",
    formSubmittedMessage: "¡Gracias por su mensaje! Nos pondremos en contacto con usted pronto.",
    weatherConditions: {
      Clear: "Despejado",
      "Partly cloudy": "Parcialmente nublado",
      Cloudy: "Nublado",
      Overcast: "Cubierto",
      Mist: "Niebla",
      "Patchy rain possible": "Posibilidad de lluvia dispersa",
      "Light rain": "Lluvia ligera",
      Sunny: "Soleado",
    },
    read: "LEER",
    listen: "ESCUCHAR",
  },
};

export const translate = (
  textKey: keyof Translation,
  language: "en" | "es"
): string => {
  return translations[language][textKey];
};

export const translateWeatherCondition = (
  condition: string,
  language: "en" | "es"
): string => {
  return translations[language].weatherConditions[condition] || condition;
};
