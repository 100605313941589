import axios from "axios";

const WEATHER_API_KEY = "41d9104dc1404d0489c12731241606"; // Replace with your WeatherAPI key
const WEATHER_API_URL = `https://api.weatherapi.com/v1/current.json?key=${WEATHER_API_KEY}&q=Los Angeles`;

export const fetchWeatherData = async () => {
  try {
    const response = await axios.get(WEATHER_API_URL);
    return response.data;
  } catch (error) {
    console.error("Error fetching weather data:", error);
    return null;
  }
};

export const fetchTimeData = () => {
  const now = new Date();
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "America/Los_Angeles",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return now.toLocaleTimeString("en-US", options);
};
