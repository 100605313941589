import React, { FC, useCallback, useEffect, useRef, useState, memo } from 'react';

const clamp = (value: number, min: number, max: number) => Math.max(min, Math.min(max, value));
const solveTolerancePercentage = 0.028;

interface Tile {
  tileOffsetX: number;
  tileOffsetY: number;
  tileWidth: number;
  tileHeight: number;
  correctPosition: number;
  currentPosXPerc: number;
  currentPosYPerc: number;
  solved: boolean;
}

interface JigsawPuzzleProps {
  imageSrc: string;
  rows?: number;
  columns?: number;
  onSolved?: () => void;
  preSolvedCount?: number;
}

const JigsawPuzzle: FC<JigsawPuzzleProps> = memo(({ imageSrc, rows = 3, columns = 3, onSolved = () => {}, preSolvedCount = 6 }) => {
  const [tiles, setTiles] = useState<Tile[]>([]);
  const [imageSize, setImageSize] = useState<{ width: number, height: number } | null>(null);
  const [rootSize, setRootSize] = useState<{ width: number, height: number } | null>(null);
  const [calculatedHeight, setCalculatedHeight] = useState<number | null>(null);
  const [resizedImageSrc, setResizedImageSrc] = useState<string | null>(null);
  const [isMounted, setIsMounted] = useState(false);
  const rootElement = useRef<HTMLDivElement | null>(null);
  const resizeObserver = useRef<ResizeObserver | null>(null);
  const draggingTile = useRef<{ tile: Tile, elem: HTMLElement, mouseOffsetX: number, mouseOffsetY: number } | undefined>();

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => document.body.classList.remove('no-scroll');
  }, []);

  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const resizeImage = useCallback((image: HTMLImageElement, containerWidth: number, containerHeight: number) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const imageAspectRatio = image.width / image.height;
    const containerAspectRatio = containerWidth / containerHeight;
    let resizedImageWidth: number;
    let resizedImageHeight: number;

    if (imageAspectRatio > containerAspectRatio) {
      resizedImageWidth = containerWidth;
      resizedImageHeight = containerWidth / imageAspectRatio;
    } else {
      resizedImageWidth = containerHeight * imageAspectRatio;
      resizedImageHeight = containerHeight;
    }

    canvas.width = resizedImageWidth;
    canvas.height = resizedImageHeight;
    ctx?.drawImage(image, 0, 0, resizedImageWidth, resizedImageHeight);
    return canvas.toDataURL('image/png');
  }, []);

  const getRandomOffset = (max: number) => Math.random() * max * 0.7 - max * 0.4; 

  const calculateTilePositions = useCallback((containerSize: { width: number, height: number }, columns: number, rows: number, preSolvedCount: number): Tile[] => {
    const tileWidth = containerSize.width / columns;
    const tileHeight = containerSize.height / rows;
    const initialTiles = Array.from(Array(rows * columns).keys()).map(position => {
      const correctPosX = (position % columns) * tileWidth;
      const correctPosY = Math.floor(position / columns) * tileHeight;
      return {
        correctPosition: position,
        tileHeight,
        tileWidth,
        tileOffsetX: correctPosX,
        tileOffsetY: correctPosY,
        currentPosXPerc: 0,
        currentPosYPerc: 0,
        solved: false
      };
    });

    const shuffledTiles = shuffleArray([...initialTiles]);
    const preSolvedTiles = shuffledTiles.slice(0, preSolvedCount).map(tile => ({
      ...tile,
      currentPosXPerc: tile.tileOffsetX / containerSize.width,
      currentPosYPerc: tile.tileOffsetY / containerSize.height,
      solved: true,
    }));

    const remainingTiles = shuffledTiles.slice(preSolvedCount).map(tile => {
      const randomOffsetX = getRandomOffset(tileWidth);
      const randomOffsetY = getRandomOffset(tileHeight);
      return {
        ...tile,
        currentPosXPerc: clamp((tile.tileOffsetX + randomOffsetX) / containerSize.width, 0, 1),
        currentPosYPerc: clamp((tile.tileOffsetY + randomOffsetY) / containerSize.height, 0, 1)
      };
    });

    return [...preSolvedTiles, ...remainingTiles];
  }, []);

  const onImageLoaded = useCallback((image: HTMLImageElement, containerWidth: number, containerHeight: number) => {
    const resizedImageDataURL = resizeImage(image, containerWidth, containerHeight);
    const img = new Image();
    img.onload = () => {
      const resizedImageSize = { width: img.width, height: img.height };
      setImageSize(resizedImageSize);
      setResizedImageSrc(resizedImageDataURL);
      const scatteredTiles = calculateTilePositions({ width: containerWidth, height: containerHeight }, columns, rows, preSolvedCount);
      setTiles(scatteredTiles);
    };
    img.src = resizedImageDataURL;
  }, [rows, columns, resizeImage, calculateTilePositions, preSolvedCount]);

  const onRootElementResized = useCallback((entries: ResizeObserverEntry[]) => {
    const contentRect = entries[0].contentRect;
    const width = contentRect.width;
    const height = imageSize ? (width / imageSize.width) * imageSize.height : contentRect.height;
    setRootSize({ width, height });
    setCalculatedHeight(height);
    if (imageSize) {
      const scatteredTiles = calculateTilePositions({ width, height }, columns, rows, preSolvedCount);
      setTiles(scatteredTiles);
    }
  }, [imageSize, calculateTilePositions, columns, rows, preSolvedCount]);

  const resizeHandler = useCallback(() => {
    if (rootElement.current && imageSize) {
      const width = rootElement.current.offsetWidth;
      const height = (width / imageSize.width) * imageSize.height;
      setRootSize({ width, height });
      setCalculatedHeight(height);
      const scatteredTiles = calculateTilePositions({ width, height }, columns, rows, preSolvedCount);
      setTiles(scatteredTiles);
    }
  }, [imageSize, calculateTilePositions, columns, rows, preSolvedCount]);

  const onRootElementRendered = useCallback((element: HTMLDivElement | null) => {
    if (element) {
      rootElement.current = element;
      const observer = new ResizeObserver(onRootElementResized);
      observer.observe(element);
      resizeObserver.current = observer;

      window.addEventListener('resize', resizeHandler);

      if (imageSize) {
        resizeHandler();
      }
    }
  }, [onRootElementResized, imageSize, resizeHandler]);

  useEffect(() => {
    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const handleInitialLoad = () => {
      if (rootElement.current && imageSrc) {
        const { width: containerWidth, height: containerHeight } = rootElement.current.getBoundingClientRect();
        if (containerWidth === 0 || containerHeight === 0) {
          setTimeout(handleInitialLoad, 100);
          return;
        }
        const image = new Image();
        image.onload = () => onImageLoaded(image, containerWidth, containerHeight);
        image.src = imageSrc;
      }
    };

    if (isMounted) {
      handleInitialLoad();
    }

    window.addEventListener('resize', handleInitialLoad);
    return () => window.removeEventListener('resize', handleInitialLoad);
  }, [imageSrc, onImageLoaded, isMounted]);

  const onTileMouseDown = useCallback((tile: Tile, event: React.MouseEvent | React.TouchEvent) => {
    if (!tile.solved) {
      if (event.type === 'touchstart') {
        document.documentElement.style.setProperty('overflow', 'hidden');
      }
      const eventPos = {
        x: (event as React.MouseEvent).pageX ?? (event as React.TouchEvent).touches[0].pageX,
        y: (event as React.MouseEvent).pageY ?? (event as React.TouchEvent).touches[0].pageY
      };
      draggingTile.current = {
        tile,
        elem: event.target as HTMLDivElement,
        mouseOffsetX: eventPos.x - (event.target as HTMLDivElement).getBoundingClientRect().x,
        mouseOffsetY: eventPos.y - (event.target as HTMLDivElement).getBoundingClientRect().y
      };
      (event.target as HTMLDivElement).classList.add('jigsaw-puzzle__piece--dragging');
    }
  }, []);

  const onRootMouseMove = useCallback((event: React.MouseEvent | React.TouchEvent) => {
    if (draggingTile.current) {
      event.stopPropagation();
      event.preventDefault();
      const eventPos = {
        x: (event as React.MouseEvent).pageX ?? (event as React.TouchEvent).touches[0].pageX,
        y: (event as React.MouseEvent).pageY ?? (event as React.TouchEvent).touches[0].pageY
      };
      const draggedToRelativeToRoot = {
        x: clamp(
          eventPos.x - rootElement.current!.getBoundingClientRect().left - draggingTile.current!.mouseOffsetX,
          0,
          rootSize!.width - draggingTile.current!.elem.offsetWidth
        ),
        y: clamp(
          eventPos.y - rootElement.current!.getBoundingClientRect().top - draggingTile.current!.mouseOffsetY,
          0,
          rootSize!.height - draggingTile.current!.elem.offsetHeight
        )
      };
      draggingTile.current!.elem.style.setProperty('left', `${draggedToRelativeToRoot.x}px`);
      draggingTile.current!.elem.style.setProperty('top', `${draggedToRelativeToRoot.y}px`);
    }
  }, [rootSize]);

  const onRootMouseUp = useCallback((event: React.TouchEvent | React.MouseEvent) => {
    if (draggingTile.current && rootSize && imageSize) {
      if (event.type === 'touchend') {
        document.documentElement.style.removeProperty('overflow');
      }
      draggingTile.current.elem.classList.remove('jigsaw-puzzle__piece--dragging');
      const draggedTile = draggingTile.current.tile;
      const draggedToPercentage = {
        x: clamp(draggingTile.current.elem.offsetLeft / rootSize.width, 0, 1),
        y: clamp(draggingTile.current.elem.offsetTop / rootSize.height, 0, 1)
      };
      const targetPositionPercentage = {
        x: draggedTile.tileOffsetX / rootSize.width,
        y: draggedTile.tileOffsetY / rootSize.height
      };
      const isSolved =
        Math.abs(targetPositionPercentage.x - draggedToPercentage.x) <= solveTolerancePercentage &&
        Math.abs(targetPositionPercentage.y - draggedToPercentage.y) <= solveTolerancePercentage;

      setTiles(prevState => {
        const newState = prevState.map(tile =>
          tile.correctPosition === draggedTile.correctPosition
            ? {
                ...draggedTile,
                currentPosXPerc: isSolved ? draggedTile.tileOffsetX / imageSize.width : draggedToPercentage.x,
                currentPosYPerc: isSolved ? draggedTile.tileOffsetY / imageSize.height : draggedToPercentage.y,
                solved: isSolved
              }
            : tile
        );
        if (newState.every(tile => tile.solved)) {
          onSolved();
        }
        return newState;
      });

      if (isSolved) {
        draggingTile.current.elem.style.setProperty('left', `${draggedTile.tileOffsetX}px`);
        draggingTile.current.elem.style.setProperty('top', `${draggedTile.tileOffsetY}px`);
      }
      draggingTile.current = undefined;
    }
  }, [rootSize, imageSize, onSolved]);

  if (!imageSrc) {
    return null;
  }

  const instructionsText = "SOLVE THE PUZZLE".split("").map((char, index) => (
    <span key={index}>{char === " " ? '\u00A0' : char}</span>
  ));

  return (
    <>
      <h1 className="puzzle-instructions">{instructionsText}</h1>
      <div
        ref={onRootElementRendered}
        className="jigsaw-puzzle"
        style={{
          width: '100%',
          height: calculatedHeight ? `${calculatedHeight}px` : '100%',
          position: 'relative',
          touchAction: 'none',
          userSelect: 'none',
        }}
        onMouseMove={onRootMouseMove}
        onTouchMove={onRootMouseMove}
        onMouseUp={onRootMouseUp}
        onTouchEnd={onRootMouseUp}
        onDragStart={event => {
          event.stopPropagation();
          event.preventDefault();
        }}
        onDragOver={event => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        {rootSize && tiles.map(tile => (
          <div
            onMouseDown={event => onTileMouseDown(tile, event)}
            onTouchStart={event => onTileMouseDown(tile, event)}
            key={tile.correctPosition}
            className={`jigsaw-puzzle__piece ${tile.solved ? ' jigsaw-puzzle__piece--solved' : ' jigsaw-puzzle__piece--unsolved'}`}
            data-position={tile.correctPosition}
            style={{
              position: 'absolute',
              height: `${100 / rows}%`,
              width: `${100 / columns}%`,
              backgroundImage: `url(${resizedImageSrc})`,
              backgroundSize: `${rootSize.width}px ${rootSize.height}px`,
              backgroundPosition: `${-tile.currentPosXPerc * rootSize!.width}px ${-tile.currentPosYPerc * rootSize!.height}px`,
              left: `${tile.currentPosXPerc * 100}%`,
              top: `${tile.currentPosYPerc * 100}%`
            }}
          />
        ))}
      </div>
    </>
  );
});

export default JigsawPuzzle;
